<template>
  <div id="productsgrid" class="d-flex flex-column align-center">
    <v-container>
      <div v-if="search" class="pb-2">
        <v-row v-if="false">
          <v-col cols="12" sm="3">
            <v-text-field
              v-model="productsSearch"
              append-icon="mdi-magnify"
              :label="`${$i18n.t('labels.search')}`"
              @input="queryProducts"
            ></v-text-field>
          </v-col>
          <v-col cols="6" sm="3">
            <v-combobox
              class="productsSearchInputCSS pt-2"
              v-model="computedProductsSearchTags"
              :label="`${$i18n.t('labels.tags')}`"
              multiple
              chips
              dense
              @change="queryProducts"
              min-height="36px"
              :items="syncedProductTagArray"
              deletable-chips
              clearable
              item-text="name"
              :return-object="false"
              item-value="id"
            ></v-combobox>
          </v-col>
          <v-col cols="6" sm="3">
            <v-checkbox
              v-model="viewMySubscribedProducts"
              @change="queryProducts"
              :disabled="!userGroups?.includes('teacher')"
              :label="`${
                $i18n.t('headings.subscribed') +
                ' ' +
                $i18n.t('headings.products')
              }`"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" sm="3">
            <v-checkbox
              v-model="viewMyBoughtProducts"
              @change="queryProducts"
              :disabled="
                !syncedBoughtProducts || syncedBoughtProducts.length === 0
              "
              :label="`${
                $i18n.t('headings.bought') + ' ' + $i18n.t('headings.products')
              }`"
            ></v-checkbox>
          </v-col>
          <v-col cols="6" sm="3">
            <!-- <v-combobox
            class="productsSearchInputCSS pt-2"
            v-model="computedProductsSearchCategories"
            label="Categories"
            multiple
            chips
            dense
            @change="queryProducts"
            min-height="36px"
            :items="computedProductCategoryArray"
            deletable-chips
            clearable
          ></v-combobox> -->
            <v-menu
              v-model="searchProductCategoriesMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :nudge-bottom="-12"
              :rounded="false"
            >
              <template v-slot:activator="{ on }">
                <div @click="toggleMenu">
                  <v-combobox
                    class="productsSearchInputCSS pt-2"
                    :value="computedProductsSearchCategories"
                    :label="`${$i18n.t('headings.categories')}`"
                    multiple
                    chips
                    dense
                    min-height="36px"
                    clearable
                    v-on="on"
                    readonly
                    @change="changeProductsSearchCategories"
                    @click:clear="productsSearchCategories = []"
                  ></v-combobox>
                </div>
              </template>
              <v-card>
                <v-card-text>
                  <v-treeview
                    :items="categoryTree"
                    color="primary"
                    selected-color="primary"
                    selectable
                    item-key="id"
                    open-on-click
                    selection-type="independent"
                    v-model="productsSearchCategories"
                    @input="changeProductsSearchCategories"
                  ></v-treeview>
                </v-card-text>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <!-- <v-col class="py-0"> -->
          <v-checkbox
            v-model="showFeatured"
            label="Featured Products"
          ></v-checkbox>
          <!-- </v-col -->
        </v-row>
        <v-row
          class="flex-row flex-wrap mx-0 mb-3 justify-center"
          no-gutters
          v-if="routeCategory || mainCategories.length > 0"
          style="margin-left: -4px; margin-right: -4px"
        >
          <!-- <v-btn
          v-for="(routeCategory, categoryIndex) in routeCategoryLinksTree"
          :to="`/app/products/category/${routeCategory.id}`"
          :key="categoryIndex"
          class="mx-2"
          x-small
        >
          {{ routeCategory.name }}
        </v-btn> -->
          <div
            class="d-flex flex-row flex-wrap justify-center"
            style="gap: 8px"
          >
            <v-btn @click="routeCategoriesHome" color="primary"
              ><v-icon>mdi-home</v-icon>
            </v-btn>

            <div
              v-if="routeCategory"
              class="d-flex flex-row flex-wrap justify-center"
              style="width: calc(100% - 72px); gap: 8px"
            >
              <!-- <span
                style="
                  font-size: 24px;
                  line-height: 28px;
                  margin-left: 4px;
                  padding-top: 5px;
                "
              >
              </span> -->
              <template
                v-for="(routeCategory, categoryIndex) in routeCategoryLinksTree"
              >
                <v-btn
                  :key="categoryIndex"
                  color="primary"
                  @click="changeRouteCategory(routeCategory.id)"
                >
                  {{ routeCategory.name }}
                </v-btn>
                <!-- <span
                  v-if="categoryIndex !== routeCategoryLinksTree.length - 1"
                  :key="'separator-' + categoryIndex"
                  style="font-size: 24px; line-height: 28px; padding-top: 5px"
                >
                  /
                </span> -->
              </template>
            </div>
          </div>
        </v-row>

        <v-row
          v-if="routeCategoryChildren.length > 0"
          style="gap: 8px"
          class="flex-row flex-wrap mx-0 mb-3 justify-center"
        >
          <v-btn
            v-for="(routeChild, childIndex) in routeCategoryChildren"
            :key="childIndex"
            @click="changeRouteCategory(routeChild.id)"
          >
            {{ routeChild.name }}
          </v-btn>
        </v-row>
        <v-row
          v-else-if="!routeCategory"
          style="gap: 8px"
          class="flex-row flex-wrap mx-0 mb-3 justify-center"
        >
          <v-btn
            v-for="(mainCategory, mainCategoryIndex) in mainCategories"
            :key="mainCategoryIndex"
            @click="changeRouteCategory(mainCategory.id)"
          >
            {{ mainCategory.name }}
          </v-btn>
        </v-row>
      </div>

      <v-row v-if="!queriedProducts">
        <v-col cols="12" class="d-flex align-center justify-center pa-4 pb-6">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          v-for="product in products"
          :key="product.id"
          cols="12"
          sm="6"
          md="4"
          lg="3"
        >
          <v-card
            elevation="2"
            min-height="fit-content"
            height="100%"
            @mouseover="hoverIndex = product.id"
            @mouseleave="hoverIndex = null"
            @click="selectProduct(product.id)"
            class="d-flex flex-column justify-space-between"
          >
            <div class="pb-4">
              <v-card-title
                v-if="product.featured === true"
                class="secondary justify-center py-1"
                ><v-spacer></v-spacer>{{ $i18n.t("headings.featured")
                }}<v-spacer></v-spacer
                ><v-icon v-if="product.featured === true" color="black">
                  mdi-star
                </v-icon></v-card-title
              >
            </div>
            <v-img
              :src="
                hoverIndex === product.id && product.thumbnailImages.length > 1
                  ? product.thumbnailImages[1]
                  : product.thumbnailImages[0]
              "
              height="200"
              contain
              @mouseenter="mouseOverProductThumbnail(product.id)"
              @mouseleave="mouseOutProductThumbnail"
              :loading="!isImageLoaded(product.id, product.thumbnailImages)"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0 align-center justify-center">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <v-row no-gutters class="justify-end align-end flex-nowrap">
              <v-card-title
                style="
                  font-size: 1.25rem;
                  line-height: 1.4rem;
                  text-decoration: none !important;
                  word-break: break-word;
                "
                >{{ product.title }}</v-card-title
              >
              <v-spacer></v-spacer>
              <div
                class="d-flex flex-column-reverse pa-3 align-end"
                style="height: 100%"
                v-if="
                  syncedTeacherProfileModel?.productSubscriptions?.some(
                    (subscription) => subscription.productId === product.id
                  ) ||
                  syncedBoughtProducts?.some((id) => id === product.id) ||
                  syncedPendingProducts?.some((id) => id === product.id)
                "
              >
                <v-icon
                  v-if="syncedPendingProducts?.some((id) => id === product.id)"
                  color="primary"
                >
                  mdi-cart-check
                </v-icon>
                <v-icon
                  v-if="
                    syncedTeacherProfileModel?.productSubscriptions?.some(
                      (subscription) => subscription.productId === product.id
                    )
                  "
                  color="primary"
                >
                  mdi-book-check
                </v-icon>
                <v-icon
                  v-if="syncedBoughtProducts?.some((id) => id === product.id)"
                  color="primary"
                >
                  mdi-cash-check
                </v-icon>
              </div>
            </v-row>
            <!-- <v-card-subtitle class="text-caption"
              >R {{ product.price }}</v-card-subtitle
            > -->
          </v-card>
        </v-col>
      </v-row>

      <!-- View more button -->
      <v-btn
        v-if="products.length > 0"
        color="primary"
        class="mt-5"
        :class="
          screenWidth < 355 ? 'mb-16' : screenWidth < 577 ? 'mb-12' : 'mb-6'
        "
        @click="loadMore"
        >{{ $i18n.t("actions.view") + " " + $i18n.t("labels.more") }}</v-btn
      >
      <div
        v-else-if="queriedProducts"
        class="d-flex align-center justify-center pa-4"
      >
        <span>No products found.</span>
      </div>
    </v-container>

    <v-dialog
      v-if="productSelectDialog"
      v-model="productSelectDialog"
      :max-width="screenWidth > 1400 ? '1200px' : ''"
    >
      <v-card class="overflow-x-hidden overflow-y-auto" max-height="90vh">
        <v-row no-gutters>
          <div
            :style="
              screenWidth >= 1200
                ? 'flex: 0 0 35%; max-width: 35%'
                : screenWidth >= 800
                ? 'flex: 0 0 50%; max-width: 50%'
                : 'flex: 0 0 100%; max-width: 100%'
            "
            class="pa-6"
          >
            <v-row no-gutters style="max-height: 100%">
              <v-col cols="2" style="max-height: 340px; overflow: hidden">
                <div
                  id="selectedProductThumbnails"
                  style="overflow-y: scroll; max-height: 340px; gap: 12px"
                  class="d-flex flex-column"
                >
                  <div
                    v-for="(thumbnailImg, i) in selectedProduct.thumbnailImages"
                    :key="i"
                    @mouseenter="mouseOverSelectedProductThumbnail(i)"
                    @click="clickSelectedProductThumbnail(i)"
                    @mouseleave="mouseOutSelectedProductThumbnail"
                  >
                    <v-img
                      max-height="100%"
                      max-width="100%"
                      :src="thumbnailImg"
                      cover
                      style="
                        border-radius: 4px;
                        border-style: solid;
                        border-width: thin;
                        border-color: #ffffff;
                      "
                      :style="
                        selectedProductImgHoveringIndex === i
                          ? 'border-color: #fae472 !important;'
                          : selectedProductThumbnailIndex === i
                          ? 'border-color: #ffa9b8 !important;'
                          : ''
                      "
                    ></v-img>
                  </div>
                </div>
              </v-col>
              <v-col cols="10" class="pl-3" style="max-height: 340px">
                <v-img
                  max-height="100%"
                  max-width="100%"
                  :src="
                    selectedProduct.thumbnailImages[
                      selectedProductThumbnailIndex
                    ]
                  "
                  contain
                  style="border-radius: 4px"
                ></v-img
              ></v-col>
            </v-row>
          </div>

          <div
            v-if="screenWidth >= 800"
            :style="
              screenWidth >= 1200
                ? 'flex: 0 0 35%; max-width: 35%'
                : 'flex: 0 0 50%; max-width: 50%'
            "
            class="pa-6"
          >
            <v-card-title class="px-0 pt-0 pb-2">
              <span
                class="text-h5 font-weight-bold"
                style="word-break: normal"
                >{{ selectedProduct.title }}</span
              >
            </v-card-title>
            <v-card-subtitle
              class="pa-0 pt-2"
              v-if="computeSelectedProductCategory() !== ''"
            >
              {{ $i18n.t("headings.category") }}:
              <span class="grey--text">{{
                computeSelectedProductCategory()
              }}</span>
            </v-card-subtitle>
            <v-card-subtitle
              class="px-0 py-1"
              v-if="computeSelectedProductTags() !== ''"
            >
              {{ $i18n.t("labels.tags") }}:
              <span class="grey--text">{{ computeSelectedProductTags() }}</span>
            </v-card-subtitle>
            <div v-if="screenWidth >= 1200">
              <v-expansion-panels flat v-if="selectedProduct.description">
                <v-expansion-panel>
                  <v-expansion-panel-header class="text-subtitle-2 pl-0">{{
                    $i18n.t("headings.product") +
                    " " +
                    $i18n.t("labels.details")
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      class="pt-2 tiptap"
                      v-html="selectedProduct.description"
                    ></div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <div v-else>
              <!-- <v-card-title class="px-0 pt-0 pb-2">
                  <span class="text-h5 font-weight-bold">{{
                    `R ${Number(selectedProduct.price).toFixed(2)}`
                  }}</span>
                </v-card-title>
                <v-card-subtitle class="px-0 py-2 grey--text">
                  {{ selectedProductVat }}
                </v-card-subtitle> -->
              <v-btn
                v-if="productAddToMyLibrary"
                color="primary"
                :disabled="gettingProductContent"
                :loading="gettingProductContent"
                @click="saveGetProductContent"
                class="mt-1"
                block
                >{{ $i18n.t("products.addToMyLibrary") }}</v-btn
              >
              <v-btn
                v-else-if="productDownloadLimitReached"
                color="warning"
                to="/app/profile"
                block
                class="mt-1"
                >{{ currentMonthLimit() }}</v-btn
              >
              <v-btn
                v-else-if="productSubToGradeToDownload"
                color="warning"
                to="/app/profile"
                block
                class="mt-1"
                >{{ $i18n.t("products.subToGradeToDownload") }}</v-btn
              >
              <v-btn
                v-else-if="productShowContent"
                color="primary"
                :disabled="gettingProductContent"
                :loading="gettingProductContent"
                @click="showProductContentDialog"
                block
                class="mt-1"
                >{{
                  $i18n.t("actions.show") + " " + $i18n.t("headings.content")
                }}</v-btn
              >
              <v-btn
                v-else-if="productSubscribable"
                color="warning"
                class="mt-1"
                to="/app/profile"
                block
                >{{ $i18n.t("products.subToDownload") }}</v-btn
              >
              <div
                v-if="productBuyable"
                class="d-flex flex-column pt-2 text-center"
                style="gap: 8px"
              >
                <span class="text-h6" v-show="productSubscribable">{{
                  $i18n.t("otherText.or")
                }}</span>
                <v-btn
                  color="primary"
                  @click="attemptBuyProduct(selectedProduct)"
                  block
                  :loading="savingProductToCart || pendingSaveProductToCart"
                  :disabled="
                    productExistsOnPendingOrder ||
                    savingProductToCart ||
                    pendingSaveProductToCart
                  "
                  >{{ $i18n.t("products.buyNow") }}
                  <div v-html="selectedProductPrice"></div>
                </v-btn>
              </div>
            </div>
          </div>

          <div
            v-if="screenWidth >= 1200"
            style="flex: 0 0 30%; max-width: 30%"
            class="pa-6 d-flex flex-column justify-space-between"
          >
            <div>
              <!-- <v-card-title class="px-0 pt-0 pb-2">
                  <span class="text-h5 font-weight-bold">{{
                    `R ${Number(selectedProduct.price).toFixed(2)}`
                  }}</span>
                </v-card-title>
                <v-card-subtitle class="px-0 py-2 grey--text">
                  {{ selectedProductVat }}
                </v-card-subtitle> -->

              <v-btn
                v-if="productAddToMyLibrary"
                color="primary"
                :disabled="gettingProductContent"
                :loading="gettingProductContent"
                @click="saveGetProductContent"
                block
                class="mt-1"
                >{{ $i18n.t("products.addToMyLibrary") }}</v-btn
              >
              <v-btn
                v-else-if="productDownloadLimitReached"
                color="warning"
                to="/app/profile"
                block
                class="mt-1"
                >{{ currentMonthLimit() }}</v-btn
              >
              <v-btn
                v-else-if="productSubToGradeToDownload"
                color="warning"
                to="/app/profile"
                block
                class="mt-1"
                >{{ $i18n.t("products.subToGradeToDownload") }}</v-btn
              >
              <v-btn
                v-else-if="productShowContent"
                color="primary"
                :disabled="gettingProductContent"
                :loading="gettingProductContent"
                @click="showProductContentDialog"
                block
                class="mt-1"
                >{{
                  $i18n.t("actions.show") + " " + $i18n.t("headings.content")
                }}</v-btn
              >
              <v-btn
                v-else-if="productSubscribable"
                color="warning"
                class="mt-1"
                to="/app/profile"
                block
                >{{ $i18n.t("products.subToDownload") }}</v-btn
              >
              <div
                v-if="productBuyable"
                class="d-flex flex-column pt-2 text-center"
                style="gap: 8px"
              >
                <span class="text-h6" v-show="productSubscribable">{{
                  $i18n.t("otherText.or")
                }}</span>
                <v-btn
                  color="primary"
                  @click="attemptBuyProduct(selectedProduct)"
                  block
                  :loading="savingProductToCart || pendingSaveProductToCart"
                  :disabled="
                    productExistsOnPendingOrder ||
                    savingProductToCart ||
                    pendingSaveProductToCart
                  "
                  >{{ $i18n.t("products.buyNow") }}
                  <div v-html="selectedProductPrice"></div>
                </v-btn>
              </div>
            </div>
            <div class="d-flex justify-end">
              <v-btn
                color="secondary"
                class="black--text"
                @click="closeSelectProductDialog"
                >{{ $i18n.t("actions.close") }}</v-btn
              >
            </div>
          </div>

          <v-col v-if="screenWidth < 800" cols="12" class="pa-6">
            <v-card-title class="px-0 pt-0 pb-2">
              <span
                class="text-h5 font-weight-bold"
                style="word-break: normal"
                >{{ selectedProduct.title }}</span
              >
            </v-card-title>
            <!-- <v-card-subtitle
              class="px-0 py-2 grey--text"
              v-if="selectedProduct.tags.length > 0"
            >
              {{ selectedProduct.tags.join(" / ") }}
            </v-card-subtitle> -->
            <v-card-subtitle
              class="pa-0 pt-2"
              v-if="computeSelectedProductCategory() !== ''"
            >
              {{ $i18n.t("headings.category") }}:
              <span class="grey--text">{{
                computeSelectedProductCategory()
              }}</span>
            </v-card-subtitle>
            <v-card-subtitle
              class="px-0 py-1"
              v-if="computeSelectedProductTags() !== ''"
            >
              {{ $i18n.t("labels.tags") }}:
              <span class="grey--text">{{ computeSelectedProductTags() }}</span>
            </v-card-subtitle>
            <!-- <v-card-title class="px-0 pt-0 pb-2">
                <span class="text-h5 font-weight-bold">{{
                  `R ${Number(selectedProduct.price).toFixed(2)}`
                }}</span>
              </v-card-title>
              <v-card-subtitle class="px-0 py-2 grey--text">
                {{ selectedProductVat }}
              </v-card-subtitle> -->

            <v-btn
              v-if="productAddToMyLibrary"
              color="primary"
              :disabled="gettingProductContent"
              :loading="gettingProductContent"
              @click="saveGetProductContent"
              block
              class="mt-1"
              >{{ $i18n.t("products.addToMyLibrary") }}</v-btn
            >
            <v-btn
              v-else-if="productDownloadLimitReached"
              color="warning"
              to="/app/profile"
              block
              class="mt-1"
              >{{ currentMonthLimit() }}</v-btn
            >
            <v-btn
              v-else-if="productSubToGradeToDownload"
              color="warning"
              to="/app/profile"
              block
              class="mt-1"
              >{{ $i18n.t("products.subToGradeToDownload") }}</v-btn
            >
            <v-btn
              v-else-if="productShowContent"
              color="primary"
              :disabled="gettingProductContent"
              :loading="gettingProductContent"
              @click="showProductContentDialog"
              block
              class="mt-1"
              >{{
                $i18n.t("actions.show") + " " + $i18n.t("headings.content")
              }}</v-btn
            >
            <v-btn
              v-else-if="productSubscribable"
              color="warning"
              class="mt-1"
              to="/app/profile"
              block
              >{{ $i18n.t("products.subToDownload") }}</v-btn
            >
            <div
              v-if="productBuyable"
              class="d-flex flex-column pt-2 text-center"
              style="gap: 8px"
            >
              <span class="text-h6" v-show="productSubscribable">{{
                $i18n.t("otherText.or")
              }}</span>
              <v-btn
                color="primary"
                @click="attemptBuyProduct(selectedProduct)"
                block
                :loading="savingProductToCart || pendingSaveProductToCart"
                :disabled="
                  productExistsOnPendingOrder ||
                  savingProductToCart ||
                  pendingSaveProductToCart
                "
                >{{ $i18n.t("products.buyNow") }}
                <div v-html="selectedProductPrice"></div>
              </v-btn>
            </div>
          </v-col>

          <v-col
            v-if="screenWidth < 1200"
            :cols="screenWidth < 800 ? '12' : '10'"
            class="pa-6"
          >
            <v-expansion-panels flat v-if="selectedProduct.description">
              <v-expansion-panel>
                <v-expansion-panel-header class="text-subtitle-2 pl-0">{{
                  $i18n.t("headings.product") + " " + $i18n.t("labels.details")
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    class="pt-2 tiptap"
                    v-html="selectedProduct.description"
                  ></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col
            v-if="screenWidth < 1200"
            :cols="screenWidth < 800 ? '12' : '2'"
            :class="screenWidth < 800 ? 'px-6 pb-6' : 'pa-6'"
          >
            <div class="d-flex justify-end align-end" style="height: 100%">
              <v-btn
                color="secondary"
                class="black--text"
                @click="closeSelectProductDialog"
                >{{ $i18n.t("actions.close") }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-if="physicalQuestionsDialog"
      v-model="physicalQuestionsDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h4">Product Questions</span>
        </v-card-title>

        <v-form
          ref="productQuestionsForm"
          v-model="validQuestions"
          @submit.prevent="addToCart"
        >
          <v-row class="ma-4 flex-column">
            <template
              v-for="(question, questionIndex) in selectedProduct.questions"
            >
              <v-text-field
                v-if="question.type === 'Text'"
                :key="'text-' + questionIndex"
                v-model="question.value"
                :label="question.label"
                :hint="question.hint"
                :placeholder="question.placeholder"
                @dblclick="copyPlaceholderToValue(question)"
                :required="question.required"
                :rules="question.required ? formRules.required : []"
              ></v-text-field>
              <v-textarea
                v-if="question.type === 'Textarea'"
                :key="'textarea-' + questionIndex"
                v-model="question.value"
                :label="question.label"
                :hint="question.hint"
                :placeholder="question.placeholder"
                @dblclick="copyPlaceholderToValue(question)"
                auto-grow
                :required="question.required"
                :rules="question.required ? formRules.required : []"
              ></v-textarea>
              <v-select
                v-if="
                  question.type === 'Select' || question.type === 'Multi Select'
                "
                :key="'select-' + questionIndex"
                v-model="question.value"
                :label="question.label"
                :items="question.items"
                :hint="question.hint"
                :placeholder="question.placeholder"
                :multiple="question.type === 'Multi Select'"
                :required="question.required"
                :rules="question.required ? formRules.required : []"
              ></v-select>
              <v-radio-group
                v-if="question.type === 'Radio'"
                :key="'radio-' + questionIndex"
                v-model="question.value"
                :label="question.label"
                :hint="question.hint"
                :rules="question.required ? formRules.required : []"
              >
                <v-radio
                  v-for="(item, itemIndex) in question.items"
                  :key="'item-' + itemIndex"
                  :label="item"
                  :value="item"
                ></v-radio>
              </v-radio-group>
              <div
                v-if="question.type === 'Checkbox'"
                :key="'checkbox-container-' + questionIndex"
              >
                <span class="v-label">{{ question.label }}</span>
                <template>
                  <v-checkbox
                    v-for="(item, itemIndex) in question.items"
                    :key="'checkbox-' + questionIndex + '-' + itemIndex"
                    v-model="question.value"
                    :value="item"
                    :label="item"
                    :rules="question.required ? formRules.required : []"
                  ></v-checkbox>
                </template>
              </div>
            </template>
          </v-row>

          <v-card-actions>
            <v-btn
              color="secondary"
              class="black--text"
              @click="closePhysicalQuestionsDialog"
              >{{ $i18n.t("actions.close") }}</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="addToCart()"
              :loading="savingProductToCart"
              :disabled="productExistsOnPendingOrder || !validQuestions"
              >{{ $i18n.t("products.addToCart") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedProductContent"
      persistent
      v-model="productContentDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="justify-center">
          <span class="text-h4">{{
            $i18n.t("headings.product") + " " + $i18n.t("headings.content")
          }}</span>
        </v-card-title>

        <v-row class="ma-0" v-if="!selectedProductContent.message">
          <div
            v-show="selectedProductContent.files.length > 0"
            style="width: 100%"
          >
            <v-card-title class="justify-center pb-0">
              <span class="text-h5">{{ $i18n.t("labels.files") }}</span>
            </v-card-title>
            <div class="pb-1" style="width: 100%">
              <v-col
                cols="12"
                v-for="(file, fileIndex) in selectedProductContent.files"
                :key="fileIndex"
              >
                <v-btn
                  color="primary"
                  @click="downloadFile(file.fileUrl)"
                  block
                  >{{ file.fileName }}</v-btn
                >
              </v-col>
            </div>
          </div>
          <div
            v-show="selectedProductContent.urls.length > 0"
            style="width: 100%"
            class="px-3 pb-1"
          >
            <v-card-title class="justify-center pb-1">
              <span class="text-h5">{{ $i18n.t("labels.urls") }}</span>
            </v-card-title>
            <ul>
              <li
                v-for="(url, urlIndex) in selectedProductContent.urls"
                :key="urlIndex"
              >
                <a :href="url" target="_blank">{{ url }}</a>
              </li>
            </ul>
            <!-- <v-col
              cols="12"
              v-for="(url, urlIndex) in selectedProductContent.urls"
              :key="urlIndex"
            >
              <v-btn color="primary" @click="downloadFile(url)" block>{{
                url
              }}</v-btn>
            </v-col> -->
          </div>
        </v-row>

        <v-row v-else justify="center" no-gutters>
          {{ selectedProductContent.message }}
        </v-row>

        <v-card-actions>
          <v-btn
            color="secondary"
            class="black--text"
            @click="closeProductContentDialog"
            >{{ $i18n.t("actions.close") }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <AuthenticatorDialog
      v-if="authenticatorDialog"
      @loggedIn="loggedInContinueWithPurchase"
    /> -->
  </div>
</template>

<script>
import router from "@/router";
import { mapState } from "vuex";
import { DataStore, SortDirection, Storage, API } from "aws-amplify";
import {
  Product,
  TeacherProfile,
  ProductTag,
  ProductCategory,
  TeacherProductsOrder,
} from "@/models";
import _ from "lodash";
// import AuthenticatorDialog from "@/core/services/AuthenticatorDialog.vue";
// import Helper from "@/components/global-components/Helper.vue";

export default {
  name: "ProductsGrid",
  components: {
    // AuthenticatorDialog,
    // ,Helper
  },

  props: {
    search: {
      type: Boolean,
      required: false,
    },
    featured: {
      type: Boolean,
      required: false,
      default: false,
    },
    productLimit: {
      type: Number,
      required: false,
    },
  },

  data: () => ({
    showFeatured: false,
    initiallyLoaded: false,
    queriedProducts: false,
    hoverIndex: -1,

    validQuestions: false,

    productSubscription: null,
    syncedProductArray: [],
    products: [],
    loadedProductThumbnails: [],
    savingProductToCart: false,
    pendingSaveProductToCart: false,

    // teacherProfileSubscription: null,
    // syncedTeacherProfileModel: null,

    syncedProductTagArray: [],
    tagSubscription: null,

    syncedProductCategoryArray: [],
    syncedSubscribedCategoryIDArray: [],
    categorySubscription: null,
    routeCategory: null,
    routeCategoryArray: [],
    mainCategories: [],

    gettingProductContent: false,

    selectedProduct: {
      id: "",
      title: "",
      description: "",
      price: 0,
      thumbnailImages: [],
      thumbnails: [],
      tags: [],
      questions: [],
      publishDate: "",
      physical: false,
      subscriptionProduct: false,
      eCommerceProduct: false,
    },
    selectedProductThumbnailIndex: 0,
    selectedProductImgHoveringIndex: -1,
    selectedProductContent: null,

    productSelectDialog: false,
    productContentDialog: false,
    // authenticatorDialog: false,
    physicalQuestionsDialog: false,

    productsSearch: "",
    productsSearchTags: [],
    productsSearchCategories: [],
    viewMySubscribedProducts: false,
    viewMyBoughtProducts: false,

    productsDataStoreLimit: 4,

    searchProductCategoriesMenu: false,

    formRules: {
      required: [(v) => !!v || "Required"],
    },
  }),

  async mounted() {
    this.showFeatured = this.featured ? this.featured : false;
    try {
      if (router.currentRoute.path.includes("subscribed-products")) {
        this.viewMySubscribedProducts = true;
      } else if (router.currentRoute.path.includes("bought-products")) {
        this.viewMyBoughtProducts = true;
      }

      this.productsDataStoreLimit = this.productLimit ? this.productLimit : 4;

      await this.syncData();
    } catch (error) {
      console.log(error);
    } finally {
      if (!this.initiallyLoaded) {
        this.initiallyLoaded = true;
      }
    }
  },

  computed: {
    ...mapState({
      offline: (state) => !state.isOnline,
      syncedTeacherProfile: (state) => state.syncedTeacherProfile,
      syncedTeacherProductsOrders: (state) => state.syncedTeacherProductsOrders,
      teacherProfilePayfastSubscriptionResponse: (state) =>
        state.teacherProfilePayfastSubscriptionResponse,
      syncedTeacherProfileModel: (state) => state.syncedTeacherProfileModel,
      syncedTeacherProductsOrderModels: (state) =>
        state.syncedTeacherProductsOrderModels,
      syncedBoughtProducts: (state) => state.syncedBoughtProducts,
      syncedPendingProducts: (state) => state.syncedPendingProducts,
      teacherProfileID: (state) => state.teacherProfileID,
      userGroups: (state) => state.userGroups,
    }),

    pageTitle() {
      return (this.viewMySubscribedProducts || this.viewMyBoughtProducts) &&
        router.currentRoute.path.includes("products")
        ? this.$i18n.t("otherText.my") + " " + this.$i18n.t("headings.library")
        : this.viewMySubscribedProducts || this.viewMyBoughtProducts
        ? this.$i18n.t("otherText.my") + " " + this.$i18n.t("headings.library")
        : this.$i18n.t("headings.resourceLibrary");
    },

    selectedProductPrice() {
      return this.selectedProduct.price &&
        this.selectedProduct.discountPercentage
        ? `<del>R${this.selectedProduct.price.toFixed(2)}</del> R` +
            (
              this.selectedProduct.price -
              this.selectedProduct.price *
                (this.selectedProduct.discountPercentage / 100)
            ).toFixed(2)
        : this.selectedProduct.price
        ? "R" + this.selectedProduct.price.toFixed(2)
        : "R" + 0;
      // return this.selectedProduct.price
      //   ? this.selectedProduct.price.toFixed(2)
      //   : 0;
    },

    selectedProductVat() {
      return `Price incl. VAT (15%) ${Number.parseFloat(
        this.selectedProduct.price
          ? this.selectedProduct.price - this.selectedProduct.price / 1.15
          : 0
      ).toFixed(2)}`;
    },

    screenWidth() {
      let width = this.$vuetify.breakpoint.width;
      return width;
    },

    routeCategoryChildren() {
      if (this.routeCategory) {
        return this.syncedProductCategoryArray.filter(
          (category) => category.parentId === this.routeCategory.id
        );
      } else return [];
    },

    routeCategoryLinksTree() {
      if (this.routeCategory) {
        let category = this.routeCategory;
        let categoryLinks = [];
        while (category) {
          categoryLinks.unshift(category);
          category = this.syncedProductCategoryArray.find(
            (cat) => cat.id === category.parentId
          );
        }
        return categoryLinks;
      } else return null;
    },

    categoryTree() {
      // Assuming syncedProductCategoryArray contains flat array of categories
      // with parentId indicating hierarchy, transform it into a tree-like structure
      return this.buildCategoryTree(this.syncedProductCategoryArray);
    },

    computedProductCategoryArray: {
      get: function () {
        return this.syncedProductCategoryArray.map((category) => {
          let parentCategories = [];

          if (category.parentId) {
            let currentCategory = category;
            while (currentCategory.parentId) {
              const parentCategory = this.syncedProductCategoryArray.find(
                (category) => category.id === currentCategory.parentId
              );
              if (parentCategory) {
                parentCategories.unshift(parentCategory.name);
                currentCategory = parentCategory;
              } else {
                break;
              }
            }
          }

          return parentCategories.length > 0
            ? parentCategories.join("/") + "/" + category.name
            : category.name;
        });
      },
    },

    computedProductsSearchCategories: {
      get: function () {
        let categories = [];
        _.forEach(this.productsSearchCategories, (categoryId) => {
          const category = _.find(this.syncedProductCategoryArray, {
            id: categoryId,
          });
          if (category) {
            let parentCategories = [];

            if (category.parentId) {
              let currentCategory = category;
              while (currentCategory.parentId) {
                const parentCategory = this.syncedProductCategoryArray.find(
                  (category) => category.id === currentCategory.parentId
                );
                if (parentCategory) {
                  parentCategories.unshift(parentCategory.name);
                  currentCategory = parentCategory;
                } else {
                  break;
                }
              }
            }

            categories.push(
              parentCategories.length > 0
                ? parentCategories.join("/") + "/" + category.name
                : category.name
            );
          }
        });
        return categories;
      },
      // set: function (newValue) {
      //   let categoryIds = [];
      //   if (newValue.length > 0) {
      //     for (const category of newValue) {
      //       const categoryParts = category.split("/");
      //       const newCategory = categoryParts[categoryParts.length - 1];

      //       if (typeof newCategory === "string" && newCategory !== "") {
      //         const category = this.syncedProductCategoryArray.find(
      //           (category) => category.name === newCategory
      //         );

      //         if (category) categoryIds.push(category.id);
      //       }
      //     }

      //     this.productsSearchCategories = categoryIds;
      //   } else {
      //     this.productsSearchCategories = [];
      //   }
      // },
    },

    computedProductsSearchTags: {
      get: function () {
        let tags = [];
        _.forEach(this.productsSearchTags, (tagId) => {
          const productTag = _.find(this.syncedProductTagArray, { id: tagId });
          if (productTag) {
            tags.push(productTag);
          }
        });
        return tags;
      },
      set: function (newValue) {
        this.productsSearchTags = newValue;
      },
    },

    productExistsOnPendingOrder() {
      if (!this.selectedProduct) return false;

      const pendingTeacherProductsOrder = _.find(
        this.syncedTeacherProductsOrderModels,
        { orderStatus: "PENDING" }
      );

      if (!pendingTeacherProductsOrder) return false;

      const productOnPendingOrder = _.find(
        pendingTeacherProductsOrder.orderProducts,
        { id: this.selectedProduct.id }
      );

      return productOnPendingOrder ? true : false;
    },

    // Check if a product is bought or subscribed
    isProductBoughtOrSubscribed() {
      const { id } = this.selectedProduct;
      return (
        this.syncedBoughtProducts?.includes(id) ||
        this.syncedTeacherProfileModel?.productSubscriptions?.some(
          (subscription) => subscription.productId === id
        )
      );
    },

    // Check if the product's download limit is reached
    productAddToMyLibrary() {
      const { id, subscriptionProduct } = this.selectedProduct;
      return (
        //  subscriptionProduct &&
        !this.isProductBoughtOrSubscribed &&
        !this.downloadLimitReached(this.selectedProduct) &&
        this.subscribedToGrade(this.selectedProduct.categoryId) &&
        (this.teacherProfilePayfastSubscriptionResponse?.status_text ===
          "ACTIVE" ||
          this.teacherProfilePayfastSubscriptionResponse
            ?.subscriptionStillValid)
      );
    },

    // Check if download limit for the product is reached
    productDownloadLimitReached() {
      const { id, subscriptionProduct } = this.selectedProduct;
      return (
        // subscriptionProduct &&
        !this.isProductBoughtOrSubscribed &&
        this.downloadLimitReached(this.selectedProduct)
      );
    },

    // Check if subscription to grade is needed to download
    productSubToGradeToDownload() {
      const { id, subscriptionProduct, categoryId } = this.selectedProduct;
      return (
        // subscriptionProduct &&
        !this.isProductBoughtOrSubscribed && !this.subscribedToGrade(categoryId)
      );
    },

    // Check if content should be shown
    productShowContent() {
      return this.isProductBoughtOrSubscribed || this.selectedProductContent;
    },

    // Check if product can be subscribed
    productSubscribable() {
      // return this.selectedProduct?.subscriptionProduct;
      return true;
    },

    // Check if the product is buyable
    productBuyable() {
      const { id, eCommerceProduct } = this.selectedProduct;
      return (
        eCommerceProduct &&
        !this.productAddToMyLibrary &&
        !this.isProductBoughtOrSubscribed
      );
    },

    // productAddToMyLibrary() {
    //   const selectedProduct = this.selectedProduct;
    //   return (
    //     selectedProduct.subscriptionProduct &&
    //     !this.syncedBoughtProducts?.some((id) => id === selectedProduct.id) &&
    //     !this.downloadLimitReached(selectedProduct) &&
    //     this.subscribedToGrade(selectedProduct.categoryId) &&
    //     !this.syncedTeacherProfileModel?.productSubscriptions?.some(
    //       (subscription) => subscription.productId === selectedProduct.id
    //     ) &&
    //     (this.teacherProfilePayfastSubscriptionResponse?.status_text ===
    //       "ACTIVE" ||
    //       this.teacherProfilePayfastSubscriptionResponse
    //         ?.subscriptionStillValid)
    //   );
    // },

    // productDownloadLimitReached() {
    //   const selectedProduct = this.selectedProduct;
    //   return (
    //     selectedProduct.subscriptionProduct &&
    //     !this.syncedBoughtProducts?.some((id) => id === selectedProduct.id) &&
    //     !this.syncedTeacherProfileModel?.productSubscriptions?.some(
    //       (subscription) => subscription.productId === selectedProduct.id
    //     ) &&
    //     this.downloadLimitReached(selectedProduct)
    //   );
    // },

    // productSubToGradeToDownload() {
    //   const selectedProduct = this.selectedProduct;
    //   return (
    //     selectedProduct.subscriptionProduct &&
    //     !this.syncedBoughtProducts?.some((id) => id === selectedProduct.id) &&
    //     !this.syncedTeacherProfileModel?.productSubscriptions?.some(
    //       (subscription) => subscription.productId === selectedProduct.id
    //     ) &&
    //     !this.subscribedToGrade(selectedProduct.categoryId)
    //   );
    // },

    // productShowContent() {
    //   const selectedProduct = this.selectedProduct;
    //   return (
    //     this.syncedBoughtProducts?.some((id) => id === selectedProduct.id) ||
    //     this.syncedTeacherProfileModel?.productSubscriptions?.some(
    //       (subscription) => subscription.productId === selectedProduct.id
    //     ) ||
    //     this.selectedProductContent
    //   );
    // },

    // productSubscribable() {
    //   return this.selectedProduct?.subscriptionProduct;
    // },

    // productBuyable() {
    //   const selectedProduct = this.selectedProduct;
    //   return (
    //     selectedProduct.eCommerceProduct &&
    //     !this.syncedBoughtProducts?.some((id) => id === selectedProduct.id) &&
    //     !this.syncedTeacherProfileModel?.productSubscriptions?.some(
    //       (subscription) => subscription.productId === selectedProduct.id
    //     ) &&
    //     (!this.teacherProfilePayfastSubscriptionResponse
    //       ?.subscriptionStillValid ||
    //       !this.subscribedToGrade(selectedProduct.categoryId) ||
    //       this.downloadLimitReached(selectedProduct))
    //   );
    // },
  },

  watch: {
    showFeatured: {
      handler() {
        this.queryProducts();
      },
    },
    productSelectDialog(val) {
      val || this.closeSelectProductDialog();
    },
    "$route.params.categoryId": {
      handler() {
        // console.log("categoryId changed");
        this.queryRouteCategory();
      },
    },
    "$route.path": {
      handler(newVal, oldVal) {
        // console.log("path changed");
        if (
          oldVal &&
          (oldVal.includes("products") || oldVal.includes("bought-products")) &&
          newVal.includes("subscribed-products")
        ) {
          this.viewMySubscribedProducts = true;
          this.viewMyBoughtProducts = false;
          this.queryProducts();
        } else if (
          oldVal &&
          (oldVal.includes("products") ||
            oldVal.includes("subscribed-products")) &&
          newVal.includes("bought-products")
        ) {
          this.viewMyBoughtProducts = true;
          this.viewMySubscribedProducts = false;
          this.queryProducts();
        } else if (
          oldVal &&
          (oldVal.includes("subscribed-products") ||
            oldVal.includes("bought-products")) &&
          newVal.includes("products")
        ) {
          this.viewMySubscribedProducts = false;
          this.viewMyBoughtProducts = false;
          this.queryProducts();
        }
      },
    },
    syncedTeacherProfile: {
      handler: async function (newVal, oldVal) {
        if (newVal && !oldVal && !this.syncedTeacherProfileModel) {
          await DataStore.save(
            new TeacherProfile({
              title: "",
              name: "",
              surname: "",
              language: "",
              province: "",
              daysPerWeek: 0,
              status: "Active",
              TeacherSchedules: [],
              TeacherClassSubjects: [],
              type: "TeacherProfile",
            })
          )
            // .then((model) => {
            //   console.log(model);
            // })
            .catch((error) => {
              console.log(error);
            });
        }
      },
      // immediate: true,
    },

    syncedTeacherProfileModel: {
      handler: async function (val) {
        if (
          val &&
          this.pendingSaveProductToCart &&
          this.syncedTeacherProductsOrders
        ) {
          // console.log(
          //   "syncedTeacherProfileModel",
          //   this.syncedTeacherProfileModel
          // );
          await this.attemptBuyProductQuestions(this.selectedProduct);

          this.pendingSaveProductToCart = false;
          // await this.buyProduct(this.selectedProduct);
        }
      },
      deep: true,
    },

    syncedTeacherProductsOrders: {
      handler: async function (val) {
        if (
          val &&
          this.pendingSaveProductToCart &&
          this.syncedTeacherProfileModel
        ) {
          // console.log(
          //   "syncedTeacherProductsOrders",
          //   this.syncedTeacherProductsOrders
          // );
          await this.attemptBuyProductQuestions(this.selectedProduct);

          this.pendingSaveProductToCart = false;
          // await this.buyProduct(this.selectedProduct);
        }
      },
      deep: true,
    },
  },

  methods: {
    copyPlaceholderToValue(question) {
      if (!question.value && question.placeholder) {
        question.value = question.placeholder;
      }
    },

    attemptBuyProductQuestions(selectedProduct) {
      const alreadyOwnsProduct =
        this.syncedBoughtProducts?.includes(selectedProduct.id) ||
        this.syncedTeacherProfileModel?.productSubscriptions?.some(
          (subscription) => subscription.productId === selectedProduct.id
        );
      const alreadyPendingProduct = this.syncedPendingProducts?.some(
        (id) => id === selectedProduct.id
      );

      if (
        selectedProduct.physical &&
        selectedProduct.questions?.length > 0 &&
        !(alreadyOwnsProduct || alreadyPendingProduct)
      ) {
        this.physicalQuestionsDialog = true;
      } else {
        this.buyProduct(selectedProduct);
      }
    },

    async addToCart() {
      try {
        await this.buyProduct(this.selectedProduct);
      } catch (error) {
        console.log(error);
      }

      this.physicalQuestionsDialog = false;
      this.validQuestions = false;
    },

    // async loggedInContinueWithPurchase() {
    //   this.authenticatorDialog = false;
    // },

    async attemptBuyProduct(selectedProduct) {
      if (this.syncedTeacherProfileModel) {
        // await this.buyProduct(selectedProduct);
        await this.attemptBuyProductQuestions(selectedProduct);
      } else {
        this.pendingSaveProductToCart = true;
        // this.authenticatorDialog = true;
        await router.push(`/app/products/${selectedProduct.id}/buy`).catch(() =>
          // error
          {
            // console.log(error);
          }
        );
      }
    },

    async buyProduct(selectedProduct) {
      this.savingProductToCart = true;

      if (
        this.syncedBoughtProducts?.includes(selectedProduct.id) ||
        this.syncedTeacherProfileModel?.productSubscriptions?.some(
          (subscription) => subscription.productId === selectedProduct.id
        )
      ) {
        await this.getProductContent();
        this.savingProductToCart = false;
        return;
      }

      const pendingTeacherProductsOrder = _.find(
        this.syncedTeacherProductsOrderModels,
        { orderStatus: "PENDING" }
      );
      // console.log("pendingTeacherProductsOrder", pendingTeacherProductsOrder);
      if (
        this.syncedTeacherProductsOrderModels.length > 0 &&
        pendingTeacherProductsOrder
      ) {
        if (
          pendingTeacherProductsOrder.orderProducts?.some(
            (product) => product.id === selectedProduct.id
          )
        ) {
          // console.log("Product already added to the pending order.");
          this.savingProductToCart = false;
          return;
        }

        let newProductsOrderArray =
          pendingTeacherProductsOrder.orderProducts?.length > 0
            ? [
                ...new Set([
                  ...pendingTeacherProductsOrder.orderProducts,
                  selectedProduct,
                ]),
              ]
            : [selectedProduct];

        await DataStore.save(
          TeacherProductsOrder.copyOf(
            pendingTeacherProductsOrder,
            (updateModel) => {
              updateModel.orderProducts = newProductsOrderArray;
            }
          )
        );
      } else {
        await DataStore.save(
          new TeacherProductsOrder({
            orderProducts: [selectedProduct],
            orderStatus: "PENDING",
            teacherprofileID: this.teacherProfileID,
          })
        );
      }

      this.savingProductToCart = false;
    },

    subscribedToGrade(selectedProductCategoryId) {
      if (!selectedProductCategoryId) return false;
      if (
        this.syncedTeacherProfileModel?.payfastSubscriptionPackage
          ?.grandFathered &&
        this.teacherProfilePayfastSubscriptionResponse?.subscriptionStillValid
      )
        return true;

      try {
        const currentCategory = this.syncedProductCategoryArray.find(
          (category) => category.id === selectedProductCategoryId
        );
        if (!currentCategory) {
          // console.error("Category not found.");
          return false;
        }

        // Get the array of grades from the synced teacher profile model
        const grades =
          this.syncedTeacherProfileModel.payfastSubscriptionPackage.grades;

        if (!currentCategory.grade && grades?.length > 0) {
          return true;
        }

        // Check if the top parent category's grade matches any in the grades array
        return grades.includes(currentCategory.grade);
      } catch (error) {
        // console.error("Error in subscribedToGrade method:", error);
        return false;
      }
    },

    currentMonthLimit() {
      return (
        this.$i18n.t("products.currentMonthLimit") +
        new Date().toLocaleString("default", { month: "long" })
      );
    },

    downloadLimitReached(selectedProduct) {
      if (
        (this.syncedTeacherProfileModel?.payfastSubscriptionPackage
          ?.grandFathered &&
          this.teacherProfilePayfastSubscriptionResponse
            ?.subscriptionStillValid) ||
        !this.syncedTeacherProfileModel?.payfastSubscriptionPackage ||
        this.syncedTeacherProfileModel?.payfastSubscriptionPackage?.limit === -1
      )
        return false;

      const now = new Date();
      const currentYear = now.getFullYear();
      const currentMonth = now.getMonth();

      const foundCategory = _.find(this.syncedProductCategoryArray, {
        id: selectedProduct.categoryId,
      });

      if (!foundCategory || !foundCategory.grade) return false;

      // Filter the subscriptions that match the given grade and have a timestamp within the current month
      const downloadsThisMonth =
        this.syncedTeacherProfileModel?.productSubscriptions?.filter(
          (subscription) => {
            if (
              !subscription.grade ||
              subscription.grade !== foundCategory.grade
            ) {
              return false;
            }

            const timestampDate = new Date(subscription.timeStamp);
            return (
              timestampDate.getFullYear() === currentYear &&
              timestampDate.getMonth() === currentMonth
            );
          }
        );

      // console.log("downloadsThisMonth.length", downloadsThisMonth?.length);
      // console.log(
      //   "limit",
      //   this.syncedTeacherProfileModel.payfastSubscriptionPackage.limit
      // );

      return (
        downloadsThisMonth?.length >=
        this.syncedTeacherProfileModel.payfastSubscriptionPackage.limit
      );
    },

    changeRouteCategory(categoryId) {
      // console.log("changeRouteCategory", categoryId);
      this.productsSearchCategories = [];

      const currentRoutePath = router.currentRoute.path;

      if (!currentRoutePath.includes(categoryId)) {
        router.push(
          `/category/${categoryId}`
          // currentRoutePath.includes("products")
          //   ? `/app/products/category/${categoryId}`
          //   : currentRoutePath.includes("subscribed-products")
          //   ? `/app/subscribed-products/category/${categoryId}`
          //   : `/app/bought-products/category/${categoryId}`
        );
      }
    },

    changeProductsSearchCategories() {
      // console.log("changeProductsSearchCategories");
      this.routeCategory = null;
      this.routeCategoryArray = [];
      this.queryProducts();
    },

    routeCategoriesHome() {
      // console.log(
      //   "routeCategoriesHome",
      //   router.currentRoute.params?.categoryId
      // );
      if (router.currentRoute.params?.categoryId) {
        this.productsSearchCategories = [];
        router.push(
          "/"
          // router.currentRoute.path.includes("products")
          //   ? "/app/products"
          //   : router.currentRoute.path.includes("subscribed-products")
          //   ? "/app/subscribed-products"
          //   : "/app/bought-products"
        );
        this.routeCategory = null;
        this.routeCategoryArray = [];
        this.queryProducts();
      }
    },

    isImageLoaded(productId, thumbnailImages) {
      if (this.loadedProductThumbnails.includes(productId)) return true;

      if (thumbnailImages.length === 0) return false;
      const img = new Image();
      img.onload = () => {
        this.loadedProductThumbnails.push(productId);
        return true;
      };
      img.onerror = () => {
        return false;
      };
      img.src = thumbnailImages[0];
      return false;
    },

    toggleMenu() {
      this.searchProductCategoriesMenu = !this.searchProductCategoriesMenu;
    },

    buildCategoryTree(categories) {
      let tree = [];

      // Create a map of categories by their ID for quicker access
      const categoryMap = {};
      categories.forEach((category) => {
        categoryMap[category.id] = { ...category, children: [] };
      });

      // Function to recursively build the tree
      const buildTree = (categoryId) => {
        const category = categoryMap[categoryId];
        if (!category) return null; // Return null if category not found

        // Recursively build children
        if (category.parentId) {
          const parentCategory = categoryMap[category.parentId];
          if (parentCategory) {
            parentCategory.children.push(category);
          }
        } else {
          tree.push(category); // Add to top-level tree if no parent
        }
      };

      // Build tree for each category
      categories.forEach((category) => {
        buildTree(category.id);
      });

      return tree;
    },

    computeSelectedProductCategory() {
      let parentCategories = [];
      if (
        this.selectedProduct.categoryId &&
        this.selectedProduct.categoryId !== ""
      ) {
        let firstCategoryId = this.selectedProduct.categoryId;

        let category = this.syncedProductCategoryArray.find(
          (category) => category.id === firstCategoryId
        );

        if (!category) return "";

        if (category.parentId) {
          let currentCategory = category;
          while (currentCategory.parentId) {
            const parentCategory = this.syncedProductCategoryArray.find(
              (category) => category.id === currentCategory.parentId
            );
            if (parentCategory) {
              parentCategories.unshift(parentCategory.name);
              currentCategory = parentCategory;
            } else {
              break;
            }
          }
        }

        return parentCategories.length > 0
          ? parentCategories.join(" / ") + " / " + category.name
          : category.name;
      } else return "";
    },

    computeSelectedProductTags() {
      let tagNames = [];
      if (this.selectedProduct.tags.length > 0) {
        this.selectedProduct.tags.forEach((tagId) => {
          const tag = this.syncedProductTagArray.find(
            (tag) => tag.id === tagId
          );
          if (tag) {
            tagNames.push(tag.name);
          }
        });
        return tagNames.length > 0 ? tagNames.join(", ") : "";
      } else return "";
    },

    mouseOverProductThumbnail(index) {
      this.hoverIndex = index;
    },

    mouseOutProductThumbnail() {
      this.hoverIndex = -1;
    },

    mouseOverSelectedProductThumbnail(index) {
      this.selectedProductImgHoveringIndex = index;
    },

    mouseOutSelectedProductThumbnail() {
      this.selectedProductImgHoveringIndex = -1;
    },

    clickSelectedProductThumbnail(index) {
      this.selectedProductThumbnailIndex = index;
    },

    closeSelectProductDialog() {
      try {
        this.selectedProduct = _.cloneDeep({
          id: "",
          title: "",
          description: "",
          price: 0,
          thumbnailImages: [],
          thumbnails: [],
          tags: [],
          questions: [],
          publishDate: "",
          physical: false,
          subscriptionProduct: false,
          eCommerceProduct: false,
        });

        this.selectedProductImgHoveringIndex = -1;
        this.selectedProductThumbnailIndex = 0;
        this.selectedProductContent = null;

        this.productSelectDialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    closePhysicalQuestionsDialog() {
      this.physicalQuestionsDialog = false;
      this.validQuestions = false;
    },

    closeProductContentDialog() {
      this.productContentDialog = false;
    },

    showProductContentDialog() {
      this.productContentDialog = true;
    },

    downloadFile(fileUrl) {
      window.open(fileUrl, "_blank");
    },

    async selectProduct(productID) {
      try {
        const newlySelectedProduct = this.products.find(
          (product) => product.id === productID
        );

        // Fetch rest of thumbnails for the selected product
        if (
          newlySelectedProduct.thumbnailImages?.length > 1 &&
          newlySelectedProduct.thumbnails.length !==
            newlySelectedProduct.thumbnailImages.length
        ) {
          for (let i = 2; i < newlySelectedProduct.thumbnails.length; i++) {
            const thumbnailKey = newlySelectedProduct.thumbnails[i];
            const thumbnailImgSrc = await Storage.get(thumbnailKey, {
              level: "public",
            });
            newlySelectedProduct.thumbnailImages.push(thumbnailImgSrc);
          }
        }

        this.selectedProduct = _.cloneDeep(newlySelectedProduct);

        this.productSelectDialog = true;

        if (
          this.syncedTeacherProfileModel?.productSubscriptions?.some(
            (subscription) => subscription.productId === productID
          )
        ) {
          this.saveGetProductContent();
        } else if (this.syncedBoughtProducts?.some((id) => id === productID)) {
          this.getProductContent();
        }
      } catch (error) {
        console.log(error);
      }
    },

    async saveGetProductContent() {
      this.gettingProductContent = true;
      // const ownsProduct = this.syncedTeacherProfileModel.products?.includes(
      //   this.selectedProduct.id
      // );

      const ownsProduct =
        this.syncedTeacherProfileModel.productSubscriptions?.some(
          (subscription) => subscription.productId === this.selectedProduct.id
        );

      if (ownsProduct) {
        await this.getProductContent();
      } else if (
        this.teacherProfilePayfastSubscriptionResponse?.status_text ===
          "ACTIVE" ||
        this.teacherProfilePayfastSubscriptionResponse?.subscriptionStillValid
      ) {
        await this.saveProductContent();
      }

      this.gettingProductContent = false;
    },

    async saveProductContent() {
      try {
        // const productIds = this.syncedTeacherProfileModel.products
        //   ? [
        //       ...this.syncedTeacherProfileModel.products,
        //       this.selectedProduct.id,
        //     ]
        //   : [this.selectedProduct.id];

        const foundCategory = _.find(this.syncedProductCategoryArray, {
          id: this.selectedProduct.categoryId,
        });

        let objToPush = {
          productId: this.selectedProduct.id,
          timeStamp: new Date().toISOString(),
        };

        if (foundCategory?.grade > "") {
          objToPush.grade = foundCategory.grade;
        }

        const productSubscriptions = this.syncedTeacherProfileModel
          .productSubscriptions
          ? [
              ...new Set([
                ...this.syncedTeacherProfileModel.productSubscriptions,
                objToPush,
              ]),
            ]
          : [objToPush];

        await DataStore.save(
          TeacherProfile.copyOf(
            this.syncedTeacherProfileModel,
            (updateModel) => {
              updateModel.productSubscriptions = productSubscriptions;
            }
          )
        );

        await this.getProductContent();
      } catch (error) {
        console.log(error);
      }
    },

    async getProductContent() {
      try {
        console.log("Getting product content...");
        await API.get("productcontentapi", "/item", {
          queryStringParameters: {
            productID: this.selectedProduct.id,
          },
          // headers: {
          //   "Content-Type": "application/json",
          //   "Access-Control-Allow-Origin": "*",
          // },
        })
          .then(async (response) => {
            if (
              response.error ||
              (response.data?.files?.length === 0 &&
                response.data?.urls?.length === 0)
            ) {
              this.selectedProductContent = {
                message: this.$i18n.t("products.noContent"),
              };
            } else {
              this.selectedProductContent = _.cloneDeep(response.data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    },

    async loadMore() {
      this.productsDataStoreLimit += 10;
      this.queryProducts();
    },

    async queryProducts() {
      try {
        // console.log("Querying products...");
        const search = this.productsSearch.split(" ");

        const currentDateTime = new Date().toISOString();

        const queriedProducts = await DataStore.query(
          Product,
          (product) =>
            product.and((f) => [
              f.or((f) => [
                f.and((f) => [
                  f.eCommerceProduct("eq", true),
                  this.syncedSubscribedCategoryIDArray.map((id) =>
                    f.categoryId("ne", id)
                  ),
                ]),
                f.subscriptionProduct("eq", true),
              ]),
              f.thumbnails("gt", []),
              f.publishDate("lt", currentDateTime),
              search
                .map((str) => str.toLowerCase())
                .every((string) => f.searchField("contains", string)),

              f.or((f) => [
                this.viewMySubscribedProducts
                  ? this.syncedTeacherProfileModel?.productSubscriptions
                      ?.length > 0
                    ? f.or((f) =>
                        this.syncedTeacherProfileModel.productSubscriptions.reduce(
                          (c, pS) => c.id("eq", pS.productId),
                          f
                        )
                      )
                    : f.id("eq", null)
                  : true,

                this.viewMyBoughtProducts
                  ? this.syncedBoughtProducts?.length > 0
                    ? f.or((f) =>
                        this.syncedBoughtProducts.reduce(
                          (c, pS) => c.id("eq", pS),
                          f
                        )
                      )
                    : f.id("eq", null)
                  : true,
              ]),
              this.showFeatured ? f.featured("eq", true) : true,
              this.productsSearchTags.some((tag) => f.tags("contains", tag)),
              this.routeCategory
                ? f.or((f) =>
                    this.routeCategoryArray.reduce(
                      (c, id) => c.categoryId("eq", id),
                      f
                    )
                  )
                : true,
              f.or((f) =>
                this.productsSearchCategories.reduce(
                  (c, id) => c.categoryId("eq", id),
                  f
                )
              ),
            ]),
          {
            limit: this.productsDataStoreLimit,
            sort: (s) =>
              s
                .featured(SortDirection.DESCENDING)
                .publishDate(SortDirection.DESCENDING)
                .title(SortDirection.DESCENDING),
          }
        );

        //  console.log("Queried products:", queriedProducts);

        this.syncedProductArray = queriedProducts;

        const mutableProducts = _.cloneDeep(queriedProducts);

        // Remove products not in mutableProducts
        this.products = this.products.filter((product) =>
          mutableProducts.some(
            (mutableProduct) =>
              mutableProduct.id === product.id &&
              mutableProduct.updatedAt === product.updatedAt
          )
        );

        mutableProducts.forEach(async (newProduct, newProductIndex) => {
          const existingProductIndex = this.products.findIndex(
            (existingProduct) =>
              existingProduct &&
              existingProduct.id === newProduct.id &&
              existingProduct.updatedAt === newProduct.updatedAt
          );

          if (existingProductIndex === -1) {
            if (newProduct.thumbnails.length > 0) {
              newProduct.thumbnailImages = [];
              for (
                let i = 0;
                i < Math.min(newProduct.thumbnails.length, 2);
                i++
              ) {
                const thumbnailKey = newProduct.thumbnails[i];
                const thumbnailImgSrc = await Storage.get(thumbnailKey, {
                  level: "public",
                });

                // Save Thumbnail images
                const img = new Image();
                img.onload = function () {
                  newProduct.thumbnailImages.push(thumbnailImgSrc);
                };
                img.onerror = function (error) {
                  console.error("Error loading thumbnail image:", error);
                };
                img.src = thumbnailImgSrc;
              }
            }
          } else {
            const existingProduct = this.products.find(
              (storedProduct) =>
                storedProduct && storedProduct.id === newProduct.id
            );

            mutableProducts.splice(newProductIndex, 1, existingProduct);
          }
        });

        this.products = mutableProducts;
      } catch (error) {
        console.log(error);
      } finally {
        this.queriedProducts = true;
      }
    },

    async queryRouteCategory() {
      if (!router.currentRoute.params?.categoryId) return;

      // const regex =
      //   /^category\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

      // if (!regex.test(router.currentRoute.params.category)) {
      //   router.push({ name: "NotFound" });
      //   return;
      // }
      // const routeCategoryParts =
      //   router.currentRoute.params?.category.split("/");

      // Extract the last part
      // const routeCategoryId = routeCategoryParts[routeCategoryParts.length - 1];

      // const routeCategory = _.find(this.syncedProductCategoryArray, {
      //   id: routeCategoryId,
      // });

      // if (routeCategory) {
      //   this.routeCategory = routeCategory;

      //   this.routeCategoryArray = this.collectCategoryIds(routeCategoryId);
      // }

      // this.queryProducts();
      const routeCategoryId = router.currentRoute.params?.categoryId;

      const routeCategory = _.find(this.syncedProductCategoryArray, {
        id: routeCategoryId,
      });

      if (routeCategory) {
        this.routeCategory = routeCategory;

        this.routeCategoryArray = this.collectCategoryIds(routeCategoryId);
      }

      await this.queryProducts();
    },

    // Helper function to recursively collect category IDs
    collectCategoryIds(categoryId) {
      let ids = [];

      // Helper function to traverse categories
      function traverseCategories(currentId) {
        // Add the current category ID to the list
        ids.push(currentId);
        // Find all children whose parentId matches currentId
        const children = this.syncedProductCategoryArray.filter(
          (cat) => cat.parentId === currentId
        );
        // Recursively traverse each child
        children.forEach((child) => traverseCategories.call(this, child.id));
      }

      traverseCategories.call(this, categoryId);

      return ids;
    },

    async syncData() {
      try {
        // this.teacherProfileSubscription = DataStore.observeQuery(
        //   TeacherProfile
        // ).subscribe(
        //   (snapshot) => {
        //     const { isSynced, items } = snapshot;
        //     if (isSynced & (items?.length > 0)) {
        //       this.syncedTeacherProfileModel = items[0];
        //     }
        //   },
        //   (error) => {
        //     console.log(error);
        //   }
        // );

        // if (!router.currentRoute.params?.category) {
        //   this.queryProducts();
        // }

        // const search = this.productsSearch.split(" ");

        // const currentDateTime = new Date().toISOString();

        this.tagSubscription = DataStore.observeQuery(ProductTag).subscribe(
          (snapshot) => {
            const { isSynced, items } = snapshot;
            if (isSynced && items?.length > 0) {
              this.syncedProductTagArray = items;
            }
          },
          (error) => {
            console.log(error);
          }
        );

        this.categorySubscription = DataStore.observeQuery(
          ProductCategory
        ).subscribe(
          (snapshot) => {
            const { isSynced, items } = snapshot;
            if (
              isSynced &&
              items?.length > 0 &&
              items !== this.syncedProductCategoryArray
            ) {
              const sortedItemsViaName = items.sort((a, b) =>
                a.name.localeCompare(b.name)
              );

              this.syncedProductCategoryArray = sortedItemsViaName;

              this.mainCategories = sortedItemsViaName.filter(
                (category) => !category.parentId
              );

              if (router.currentRoute.params?.categoryId) {
                this.queryRouteCategory();
              }

              const validGrades =
                this.syncedTeacherProfileModel?.payfastSubscriptionPackage
                  ?.grades || [];

              // Filter subjects where the grade is included in the validGrades array
              const filteredCategories = items.filter((category) =>
                validGrades.includes(category.grade)
              );

              // Map the filtered subjects to their ids
              const categoryIds = filteredCategories.map(
                (category) => category.id
              );

              this.syncedSubscribedCategoryIDArray = categoryIds;

              this.syncProducts();
            }
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    syncProducts() {
      this.productSubscription = DataStore.observeQuery(Product).subscribe(
        async (snapshot) => {
          const { isSynced } = snapshot;
          if (isSynced && !router.currentRoute.params?.categoryId) {
            await this.queryProducts();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },

  beforeDestroy() {
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }
    if (this.tagSubscription) {
      this.tagSubscription.unsubscribe();
    }
    if (this.categorySubscription) {
      this.categorySubscription.unsubscribe();
    }
    // if (this.teacherProfileSubscription) {
    //   this.teacherProfileSubscription.unsubscribe();
    // }
  },
};
</script>
<style>
/* Hiding scrollbar for Chrome, Safari and Opera */
#selectedProductThumbnails::-webkit-scrollbar {
  display: none;
}

/* Hiding scrollbar for IE, Edge and Firefox */
#selectedProductThumbnails {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.productsSearchInputCSS .v-input__slot {
  margin-bottom: 0px !important;
}

.productsSearchInputCSS .v-select__selections {
  min-height: 36px !important;
}

/* Table-specific styling */
.tiptap {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    max-width: 100% !important;
    width: auto !important;
    min-width: 200px !important;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;
}
</style>
